import React from 'react'
import { Layout, Stack, Main, Sidebar } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
import Seo from '@widgets/Seo'
import ContactForm from '@widgets/ContactForm'
import ContactInfo from '@widgets/ContactInfo'
import Commitment from '@widgets/Commitment'

const PageContact = props => (
  <Layout {...props}>
    <Seo title='Bize Ulaşın!' />
    <Divider />
    <Stack>
      <Main>
        <PageTitle
          header="Sizden Haber Bekliyoruz!"
          subheader='Yüzde 99 başarı oranı ile vize almanızı sağlıyoruz. Uzman kadromuz ile acil ve garantili vize hizmetleri için 
          lütfen telefon numaranızı bize bırakın. Size en kısa zamanda dönüş sağlayalım!'
        />
        <Divider />
        <ContactForm />
      </Main>
      <Sidebar>
        <Commitment />
        <Divider />
        <ContactInfo />
      </Sidebar>
    </Stack>
  </Layout>
)

export default PageContact
