import React from 'react'
import { Card, Text } from 'theme-ui'
import Section from '@components/Section'

const Commitment = props => (
  <Section aside title='Hakkımızda' {...props}>
    <Card variant='paper'>
      <Text variant='p'>
        Vizesigorta.com olarak turistik vize ve D tipi uzun süreli oturum vizesi almanız için ihtiyacınız olan tüm servisleri veriyoruz. Whatsapp ya da telefon numaralarımızdan bize ulaşabilirsiniz.
      </Text>
    </Card>
  </Section>
)

export default Commitment
